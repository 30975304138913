/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
import React from 'react';
import { useWindowWidth } from 'hooks';
import { FancyHighlight } from 'components/FancyHighlight';
import { FancyHighlightVariants } from 'components/FancyHighlight/FancyHighlight';
import { ModalContents } from 'enums';
import { HandleOpenModalFunction } from 'pages';
import CheckIcon from 'images/checkIcon.svg';
import XIcon from 'images/xIcon.svg';
import {
    IPricingCardContent,
    pricingCardsContent,
} from './pricingTableContent';
import {
    MobilePricingCardsWrapper,
    DesktopPricingCardsWrapper,
    FancyHighlightWrapper,
    PricingCardContent,
    PricingCardHeader,
    DesktopPricingCard,
    MobilePricingCard,
    StyledPricingSection,
    MobilePricingCardWrapperTitle,
    DesktopPricingCardsWrapperContent,
    HeaderStyles,
    PricingCardContentOptions,
    PricingCardSuggestion,
    PricingCardContentFooter,
    PricingCardContentOption,
    PricingCardContentOptionIconWrapper,
    PricingCardFooterActionButton,
    ModalStyles,
    IconWrapperStyles,
} from './satelliteComponents';

interface TPricingSectionProps {
    handleOpenModal: HandleOpenModalFunction;
}

export function PricingSection({ handleOpenModal }: TPricingSectionProps) {
    const windowWidth = useWindowWidth();

    const title = (isMobile?: boolean) => (
        <FancyHighlightWrapper isMobile={isMobile}>
            <FancyHighlight
                fancy
                animated
                variant={FancyHighlightVariants.GRADIENT}
            >
                <span>{isMobile ? 'ArcticDB Plans' : 'ArcticDB Plans'}</span>
            </FancyHighlight>
        </FancyHighlightWrapper>
    );

    const renderCardContent = (card: IPricingCardContent) => {
        return (
            <>
                <PricingCardHeader headerStyle={card.id as HeaderStyles}>
                    {card.title}
                </PricingCardHeader>
                <PricingCardContent>
                    {card.suggestion && (
                        <PricingCardSuggestion>
                            {card.suggestion}
                        </PricingCardSuggestion>
                    )}
                    <PricingCardContentOptions>
                        {card.content.map((option) => (
                            <PricingCardContentOption
                                key={option.id}
                                isQuote={option.type === 'quote'}
                            >
                                <span>{option.content}</span>
                                {option.type !== 'none' &&
                                    option.type !== 'quote' && (
                                        <PricingCardContentOptionIconWrapper>
                                            {option.type === 'excluded' && (
                                                <XIcon />
                                            )}
                                            {option.type === 'included' && (
                                                <CheckIcon />
                                            )}
                                        </PricingCardContentOptionIconWrapper>
                                    )}
                            </PricingCardContentOption>
                        ))}
                    </PricingCardContentOptions>
                    <PricingCardContentFooter>
                        <PricingCardFooterActionButton
                            onClick={() => {
                                if (card.id === 'OpenSource') {
                                    window.location.replace(
                                        'https://github.com/man-group/ArcticDB'
                                    );
                                    return;
                                }
                                handleOpenModal(ModalContents.GETARCTICDB, {
                                    contentWrapper: ModalStyles,
                                    iconWrapper: IconWrapperStyles,
                                });
                            }}
                        >
                            {card.buttonText}
                        </PricingCardFooterActionButton>
                    </PricingCardContentFooter>
                </PricingCardContent>
            </>
        );
    };

    const renderDesktopView = () => {
        return (
            <DesktopPricingCardsWrapper>
                <div>{title(false)}</div>
                <DesktopPricingCardsWrapperContent>
                    {pricingCardsContent.map((el) => (
                        <DesktopPricingCard key={el.id}>
                            {renderCardContent(el)}
                        </DesktopPricingCard>
                    ))}
                </DesktopPricingCardsWrapperContent>
            </DesktopPricingCardsWrapper>
        );
    };

    const renderMobileView = () => {
        return (
            <MobilePricingCardsWrapper>
                <MobilePricingCardWrapperTitle>
                    {title(true)}
                </MobilePricingCardWrapperTitle>
                {pricingCardsContent.map((el) => (
                    <MobilePricingCard key={el.id}>
                        {renderCardContent(el)}
                    </MobilePricingCard>
                ))}
            </MobilePricingCardsWrapper>
        );
    };

    return (
        <StyledPricingSection id="pricingSection">
            {windowWidth <= 1100 ? renderMobileView() : renderDesktopView()}
        </StyledPricingSection>
    );
}
