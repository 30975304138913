import styled from 'styled-components';
// import {theme} from '../../../../styles/theme';

export const StyledCTOQuoteSection = styled.section`
    position: relative;
    background: rgba(236, 236, 236, 0.3);
    padding: 100px 0;
`;

export const StyledBoxContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap-reverse;
    width: 100%;
    color: ${({ theme }) => theme.palette.blue['100']};

    @media screen and (max-width: 1387px) {
        flex-direction: column-reverse;
    }
`;

export const StyledWithoutImgWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 756px;
    align-items: space-around;
    justify-content: center;
    width: inherit;
`;

export const StyledDescPhotoWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
`;

export const StyledDescriptionWrapper = styled.div`
    width: 600px;

    @media screen and (max-width: 1387px) {
        width: 800px;
    }
`;

export const StyledDescription = styled.p`
    position: relative;
`;

export const StyledFigcaption = styled.figcaption`
    & > span:first-child {
        color: #000;
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        line-height: 151.182%;
    }

    & > span:last-child {
        color: rgba(0, 0, 0, 0.6);
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        line-height: 151.182%;
    }
`;

export const StyledText = styled.p`
    color: #051018;
    font-size: 20px;
    font-weight: 400;
    line-height: 151.182%;
    margin: 0;
`;

export const StyledOrangeQuoteSigns = styled.span`
    text-align: center;
    font-family: 'Open Sans';
    font-size: 184px;
    font-style: normal;
    font-weight: 600;
    line-height: 151.182%;
    color: rgba(236, 236, 236, 0.3);

    -webkit-text-stroke: 3px #db913a;
    fill: #fff;
`;

export const OrangeQuoteStart = styled(StyledOrangeQuoteSigns)`
    position: absolute;
    left: -80px;
    bottom: 140px;

    @media screen and (max-width: 1387px) {
        left: -80px;
        bottom: 80px;
    }

    @media screen and (max-width: 1000px) {
        display: none;
    }
`;

export const OrangeQuoteEnd = styled(StyledOrangeQuoteSigns)`
    position: absolute;
    right: -40px;
    top: 200px;

    @media screen and (max-width: 1387px) {
        top: 150px;
        right: -20px;
    }

    @media screen and (max-width: 1000px) {
        display: none;
    }
`;
