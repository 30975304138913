import React from 'react';
import styled from 'styled-components';

import { HeadingTypes } from 'components/Title/Title';
import { TBulletItem } from 'components/BulletList/BulletList';
import { StyledTitle } from 'components/Title';
import {
    StyledContentItem,
    StyledBulletList,
    StyledIconWrapper,
} from './satelliteComponents';

type TBox = {
    Icon: React.ElementType;
    title: string;
    bulletPoints: TBulletItem[];
    onClick?: () => void;
    className?: string;
};

export function Box({ Icon, title, bulletPoints, onClick, className }: TBox) {
    return (
        <StyledContentItem onClick={onClick} className={className}>
            <StyledIconWrapper>
                <Icon />
            </StyledIconWrapper>
            <StyledTitle variant={HeadingTypes.H3}>{title}</StyledTitle>
            <StyledBulletList items={bulletPoints} />
        </StyledContentItem>
    );
}

type TInteractiveBox = {
    active?: boolean;
};

export const StyledInteractiveBox = styled(Box)<TInteractiveBox>`
    background-color: transparent;
    border-color: transparent;
`;
