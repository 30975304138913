import React, { useState, useRef } from 'react';
import DataOnboardingIcon from 'images/dataOnboarding.svg';
import QuantitativeFinanceIcon from 'images/quantitativeFinance.svg';
import TimeseriesAnalysisIcon from 'images/timeseriesAnalysis.svg';
import { CarouselRef } from 'antd/lib/carousel/index';
import { FancyHighlight } from 'components/FancyHighlight';
import { TBoxItem } from 'types';
import { useBreakpoints } from 'hooks';
import { Box } from './Box';
import {
    StyledQuantitativeFinanceCarousel,
    StyledCarouselItem,
    StyledInteractiveBoxWithMargins,
    StyledWrapper,
    StyledHalfSpace,
    StyledTitleWrapped,
    StyledIllustration,
} from './satelliteComponents';

const boxItems: TBoxItem[] = [
    {
        id: 0,
        title: 'Quantitative Finance',
        Icon: QuantitativeFinanceIcon,
        bulletPoints: [
            {
                id: 0,
                text: `Support for DataFrames with thousands of columns enables
                   large trading universes`,
            },
            {
                id: 1,
                text: `ArcticDB is versioned, enabling point-in-time analysis of
                   research datasets`,
            },
            {
                id: 2,
                text: `Easy data updates allows workflows including daily data
                   appends and historical corrections`,
            },
        ],
    },
    {
        id: 1,
        title: 'Time-Series Analysis',
        Icon: TimeseriesAnalysisIcon,
        bulletPoints: [
            {
                id: 0,
                text: `Ingest millions of streaming ticks allowing analysis of L1,
                    L2, and order book tick data`,
            },
            {
                id: 1,
                text: `Advanced filtering allows for billions of rows and
                    thousands of columns to be processed in seconds`,
            },
        ],
    },
    {
        id: 2,
        title: 'Data Onboarding',
        Icon: DataOnboardingIcon,
        bulletPoints: [
            {
                id: 0,
                text: `Supports large concurrent writes to many tables ensuring
                    datasets can be onboarded fast and in the most convenient
                    format`,
            },
            {
                id: 1,
                text: `Scale-out architecture and built-in compression ensure
                    that networks and storage are utilised efficiently`,
            },
        ],
    },
];

export function QuantitativeFinanceBoxes() {
    const { isMobile } = useBreakpoints();
    const [activeBoxId, setActiveBoxId] = useState<number>(boxItems[0].id);
    const carouselRef = useRef<CarouselRef>(null);
    const setIdToShowProperBoxAndImage = (id: number) => {
        setActiveBoxId(id);
        carouselRef?.current?.goTo(id);
    };

    const sectionTitle = (
        <StyledTitleWrapped>
            <FancyHighlight animated fancy>
                Unleash Your Data
            </FancyHighlight>
        </StyledTitleWrapped>
    );

    if (isMobile) {
        return (
            <StyledWrapper>
                {sectionTitle}
                <StyledQuantitativeFinanceCarousel>
                    {boxItems.map(({ id, title, Icon, bulletPoints }) => (
                        <StyledCarouselItem key={id}>
                            <StyledIllustration />
                            <Box
                                title={title}
                                Icon={Icon}
                                bulletPoints={bulletPoints}
                            />
                        </StyledCarouselItem>
                    ))}
                </StyledQuantitativeFinanceCarousel>
            </StyledWrapper>
        );
    }

    return (
        <StyledWrapper>
            <StyledHalfSpace>
                {sectionTitle}
                <StyledIllustration />
            </StyledHalfSpace>
            <StyledHalfSpace>
                {boxItems.map(({ id, title, Icon, bulletPoints }) => (
                    <StyledInteractiveBoxWithMargins
                        key={id}
                        title={title}
                        Icon={Icon}
                        bulletPoints={bulletPoints}
                        active={activeBoxId === id}
                        onClick={() => setIdToShowProperBoxAndImage(id)}
                    />
                ))}
            </StyledHalfSpace>
        </StyledWrapper>
    );
}
