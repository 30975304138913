import styled from 'styled-components';

import FastIcon from 'images/fast.svg';

export const StyledFastIcon = styled(FastIcon)`
    position: relative;
    right: 4px;
`;

export const StyledDivider = styled.div`
    width: 1px;
    height: 172px;
    background: linear-gradient(
        180deg,
        rgba(128, 168, 198, 0) 0%,
        rgba(128, 168, 198, 0.47) 51.56%,
        rgba(128, 168, 198, 0) 100%
    );
`;

export const StyledBoxWrapper = styled.div`
    margin-top: 16px;

    &:first-of-type {
        margin-top: 0;
    }

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIDDLE_200}
    ) {
        margin-top: 0;
        display: flex;
        width: 33.3%;
    }
`;
