/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';
import { StyledButton } from 'components/Button';

export type HeaderStyles = 'OpenSource' | 'Pro' | 'AddOns' | 'Enterprise';

export interface TypographyStylesOptions {
    fontSize?: string;
    fontWeight?: number;
    lineHeight?: string;
    color?: string;
    fontFamily?: string;
}

export const TypographyStyles = (options?: TypographyStylesOptions) => css`
    font-size: ${() => (options?.fontSize ? options.fontSize : '16px')};
    font-weight: ${() => (options?.fontWeight ? options.fontWeight : 400)};
    line-height: ${() =>
        options?.lineHeight ? options.lineHeight : '24.96px'};
    color: ${() => (options?.color ? options.color : '#00000099')};
    font-family: ${() => (options?.fontFamily ? options.fontFamily : 'Rubik')};
`;

export const ModalStyles = () => css`
    padding: 0 !important;
`;

export const IconWrapperStyles = () => css`
    top: 16px;
`;

export const StyledPricingSection = styled.section`
    padding-block: 85px;
    background-color: #002f51;
`;

export const FancyHighlightWrapper = styled.div<{ isMobile?: boolean }>`
    margin-left: ${(props) => (props.isMobile ? '25px' : '0')};
    margin-bottom: ${(props) => (props.isMobile ? '25px' : '0')};
    span {
        font-size: ${(props) => (props.isMobile ? '36px' : '52px')};
        font-style: normal;
        font-weight: 700;
        line-height: 70px;
    }
`;

export const MobilePricingTableItemFootnote = styled.span`
    ${TypographyStyles({
        color: 'rgba(255, 255, 255, 0.6)',
        fontSize: '10px',
        fontWeight: 400,
        lineHeight: '124.682%',
        fontFamily: 'unset',
    })}
    position: relative;
    top: 5px;
`;

export const DesktopPricingCardsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 85px;
`;

export const DesktopPricingCardsWrapperContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 20px;
`;

export const MobilePricingCardsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 0 0 40px 0;
`;

export const MobilePricingCardWrapperTitle = styled.div`
    width: 360px;
    justify-items: left;
`;

export const MobilePricingCard = styled.div`
    display: flex;
    flex-direction: column;
    width: 265px;
    border: 1px solid #fff;
    border-radius: 10px;
`;

export const DesktopPricingCard = styled.div`
    display: flex;
    flex-direction: column;
    width: 265px;
    border: 1px solid #fff;
    border-radius: 10px;
`;

export const PricingCardHeader = styled.div<{
    headerStyle: HeaderStyles;
}>`
    ${TypographyStyles({ fontWeight: 600, fontSize: '24px', color: '#fff' })}
    background-color: ${(props) => {
        switch (props.headerStyle) {
            case 'OpenSource':
                return '#3B81BA';
            case 'Pro':
                return '#003E6B';
            case 'AddOns':
                return '#DAA82A';
            case 'Enterprise':
                return '#000000';
            default:
                return '#3B81BA';
        }
    }};
    text-align: left;
    padding: 8px 20px;
    border-radius: 10px 10px 0 0;
`;

export const PricingCardContent = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #f1f1f1;
    border-radius: 0px 0px 10px 10px;
`;
export const PricingCardContentFooter = styled.div`
    padding: 0 17px 0 17px;
`;

export const PricingCardContentOptions = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #ececec;
    min-height: 240px;
`;

export const PricingCardContentOption = styled.div<{ isQuote?: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    padding: 10px 17px;
    max-height: 40px;
    ${(props) =>
        props.isQuote
            ? css`
                  ${TypographyStyles({
                      color: '#606060',
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '23px',
                  })}
                  ${styled.span`
                      font-style: italic;
                      text-align: center;
                  `}
                    padding: 8px 0;
              `
            : TypographyStyles({ color: '#2b5d81' })}
`;

export const PricingCardContentOptionIconWrapper = styled.div`
    min-width: 25px;
    text-align: center;
`;

export const PricingCardSuggestion = styled.div`
    ${TypographyStyles()}
    padding: 0 17px 0 17px;
    margin: 8px 0 0 0;
    min-height: 109px;
`;

export const PricingCardFooterActionButton = styled(StyledButton)`
    width: 100% !important;
    height: 44px !important;
    padding: 10px 16px !important;
    margin: 15px 0 15px 0 !important;
    background-color: #2b516c !important;
    border-radius: 3px !important;
    border: 0 !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    font-family: 'Open Sans' !important;
    color: #fff !important;
`;
