import styled from 'styled-components';

import { StyledTitle } from 'components/Title';
import { BulletList } from 'components/BulletList';

export const StyledHeader = styled.header`
    display: flex;
    align-items: center;
`;

export const StyledHeaderTitle = styled(StyledTitle)`
    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIDDLE_200}
    ) {
        color: ${({ theme }) => theme.palette.blue['100']};
    }
`;

export const StyledHeaderIconWrapper = styled.div`
    width: 76px;
    height: 76px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
`;

export const StyledBulletList = styled(BulletList)`
    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIDDLE_200}
    ) {
        color: ${({ theme }) => theme.palette.gray['00']};
    }
`;
