import React from 'react';
import { StyledForeground } from 'components/Foreground';
import { StyledLayout } from 'components/Layout';
import { FancyHighlight } from 'components/FancyHighlight';
import MySVGImage from 'images/fullyOwnYourData_Image.svg';

import {
    StyledBoxContentWrapper,
    StyledDescPhotoWrapper,
    StyledDescriptionWrapper,
    StyledDescription,
    StyledText,
    StyledCTOQuoteSection,
    StyledTitleWrapped,
    StyledImageWrapper,
} from './satelliteComponents';

const title = 'Fully Own Your Data';
const text =
    // eslint-disable-next-line max-len
    'ArcticDB is designed with quant users in mind. It allows you to self-manage your data leveraging your preferred infrastructure. Giving you the keys to protect your most valuable asset';

export function FullyOwnYourData() {
    return (
        <StyledCTOQuoteSection>
            <StyledForeground>
                <StyledLayout>
                    <StyledBoxContentWrapper>
                        <div>
                            <StyledDescPhotoWrapper>
                                <StyledDescriptionWrapper>
                                    <StyledDescription>
                                        <StyledTitleWrapped>
                                            <FancyHighlight animated fancy>
                                                {title}
                                            </FancyHighlight>
                                        </StyledTitleWrapped>
                                        <StyledText>{text}</StyledText>
                                    </StyledDescription>
                                </StyledDescriptionWrapper>
                            </StyledDescPhotoWrapper>
                        </div>
                        <StyledImageWrapper>
                            <MySVGImage className="fullyOwnYourDataImage" />
                        </StyledImageWrapper>
                    </StyledBoxContentWrapper>
                </StyledLayout>
            </StyledForeground>
        </StyledCTOQuoteSection>
    );
}
