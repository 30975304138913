import styled from 'styled-components';

import { BulletList } from 'components/BulletList';

export const StyledContentItem = styled.div`
    background: ${({ theme }) => theme.palette.blue[10]};
    padding: 26px 24px 24px 68px;
    border: 1px solid ${({ theme }) => theme.palette.blue[10]};
    border-radius: 8px;
    position: relative;
    min-height: 306px;
`;

export const StyledBulletList = styled(BulletList)`
    margin-top: 0px;
    padding-left: 0;
    padding-top: 10px;
    list-style-type: none;

    li::marker {
        color: ${({ theme }) => theme.palette.blue[100]};
    }
`;

export const StyledIconWrapper = styled.div`
    position: absolute;
    top: 26px;
    left: 24px;
`;
