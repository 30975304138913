import React from 'react';
import styled from 'styled-components';

import { StyledForeground } from 'components/Foreground';
import { FancyHighlight } from 'components/FancyHighlight';
import { StyledLayout } from 'components/Layout';
import {
    DottedBackground,
    PatternPositions,
    PatternVariants,
} from 'components/DottedBackground';
import LogoArctic from 'images/logo.svg';
import LogoBloom from 'images/logoBBGwht.svg';
import {
    StyledDescriptionWrapper,
    StyledDescription,
    StyledDescPhotoWrapper,
    StyledBoxContentWrapper,
    StyledSubTitle,
    StyledWithoutImgWrapper,
    StyledImageWrapper,
} from './satelliteComponents';
import { ARCTIC_GITHUB } from '../../../../consts';

const StyledBloombergSection = styled.section`
    position: relative;
    background: radial-gradient(
        323.69% 63.12% at -244.53% 62.91%,
        #acc9e5 0%,
        rgba(172, 201, 229, 0) 100%
    );
    padding: 100px 0;

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIDDLE_200}
    ) {
       padding: 160px 0;
    }
`;

export function Bloomberg() {
    const boxItems = [
        {
            id: 0,
            title: 'Open Source Ethos',
            description: [
                {
                    id: 0,
                    // eslint-disable-next-line max-len, quotes
                    text: `Developed in-house at Man Group since 2018, ArcticDB empowers quant researchers, portfolio managers, traders and engineers to run billions of dollars of robust automated trading.`,
                },
                {
                    id: 1,
                    // eslint-disable-next-line max-len, quotes
                    text: `Man Group believes in Open Source technology - <a href=${ARCTIC_GITHUB}>ArcticDB is available on GitHub</a> and free to download.`,
                },
            ],
            img: (
                <StyledImageWrapper>
                    <LogoArctic className="bloomberg_logo" />
                </StyledImageWrapper>
            ),
        },
        {
            id: 1,
            title: 'Enterprise Ready',
            description: [
                {
                    id: 0,
                    // eslint-disable-next-line max-len, quotes
                    text: `In use at Man Group, ArcticDB has proven support for enterprise deployments at the world’s largest organisations.`,
                },
                {
                    id: 1,
                    // eslint-disable-next-line max-len, quotes
                    text: `ArcticDB will be integrated into Bloomberg's BQuant platform, enabling quantitative analysts and data scientists in finance to quickly build, test, deploy, and share models for alpha generation, risk and trading. Both teams will collaborate on new ArcticDB features, expanding the reach of the DataFrame revolution.`,
                },
            ],
            img: (
                <StyledImageWrapper>
                    <LogoBloom className="bloomberg_logo" />
                </StyledImageWrapper>
            ),
        },
    ];

    return (
        <StyledBloombergSection>
            <DottedBackground
                placements={{
                    [PatternPositions.TOP_LEFT]: PatternVariants.LIGHT_BG,
                }}
            />
            <StyledForeground>
                <StyledLayout>
                    {boxItems.map(({ id, title, description, img }) => (
                        <StyledBoxContentWrapper
                            key={id}
                            style={{ paddingBottom: id === 0 ? 40 : 20 }}
                        >
                            <StyledWithoutImgWrapper>
                                <StyledSubTitle>
                                    <FancyHighlight fancy animated>
                                        {title}
                                    </FancyHighlight>
                                </StyledSubTitle>
                                <StyledDescPhotoWrapper>
                                    <StyledDescriptionWrapper>
                                        {description?.map((el) => (
                                            <StyledDescription
                                                dangerouslySetInnerHTML={{
                                                    __html: el.text,
                                                }}
                                                key={el.id}
                                            />
                                        ))}
                                    </StyledDescriptionWrapper>
                                </StyledDescPhotoWrapper>
                            </StyledWithoutImgWrapper>
                            {img}
                        </StyledBoxContentWrapper>
                    ))}
                </StyledLayout>
            </StyledForeground>
        </StyledBloombergSection>
    );
}
