import { ARCTIC_EMAIL, ARCTIC_GITHUB } from 'consts';
import { Link } from 'gatsby';
import React from 'react';
import { TTheme } from 'styles/theme';
import { TColor } from 'types';
import EmailIcon from 'images/email.svg';
import GithubIcon from 'images/github_blue.svg';
import { StyledLink } from 'components/Link';
import { ParagraphTypes, StyledParagraph } from 'components/Paragraph';
import { HeadingTypes, StyledTitle } from 'components/Title';
import {
    StyledInfo,
    StyledInfoIconContainer,
    StyledInfoWrapper,
    StyledWrapper,
} from './satelliteComponents';

const blueColor: TColor = ({ theme }: { theme: TTheme }) =>
    theme.palette.blue['40'];

export function GetArcticDBModalContent() {
    return (
        <StyledWrapper>
            <StyledTitle variant={HeadingTypes.H2}>Get ArcticDB</StyledTitle>
            <StyledParagraph
                textColor={({ theme }) => theme.palette.gray['60']}
                variant={ParagraphTypes.T16}
            >
                <StyledInfoWrapper>
                    <StyledInfo>
                        <Link
                            to={`mailto:${ARCTIC_EMAIL}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <StyledInfoIconContainer>
                                <EmailIcon />
                            </StyledInfoIconContainer>
                        </Link>
                        Email us at&nbsp;
                        <StyledLink
                            href={`mailto:${ARCTIC_EMAIL}`}
                            textColor={blueColor}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {ARCTIC_EMAIL}
                        </StyledLink>
                    </StyledInfo>
                    <StyledInfo>
                        <Link
                            to={ARCTIC_GITHUB}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <StyledInfoIconContainer>
                                <GithubIcon />
                            </StyledInfoIconContainer>
                        </Link>
                        Check out the source on&nbsp;
                        <StyledLink
                            href={ARCTIC_GITHUB}
                            textColor={blueColor}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            github
                        </StyledLink>
                    </StyledInfo>
                </StyledInfoWrapper>
            </StyledParagraph>
        </StyledWrapper>
    );
}
