import React from 'react';
import styled from 'styled-components';

import { useBreakpoints } from 'hooks';
import { StyledForeground } from 'components/Foreground';
import { StyledLayout } from 'components/Layout';
import { StyledParagraph } from 'components/Paragraph';
import ClientNetworkStorageDesktopSvg from 'images/clientNetworkStorageDiagramDesktop.svg';
import ClientNetworkStorageTabletSvg from 'images/clientNetworkStorageDiagramTablet.svg';
import ClientNetworkStorageMobileSvg from 'images/clientNetworkStorageDiagramMobile.svg';
import { BOX_SHADOW } from 'consts';

export const StyledEdgeWrapper = styled.div`
    top: -108px;
    position: relative;

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIN}
    ) {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIDDLE_200}
    ) {
        top: -86px;
        margin: 0 auto;
        max-width: 1376px;
        height: 172px;
        border-radius: 12px;
        background: ${({ theme }) => theme.palette.blue['10']};
        box-shadow: ${BOX_SHADOW};
        margin-bottom: 50px;
    }

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MAX}
    ) {
        margin: 0 auto;
        width: 1376px;
        margin-left: -48px;
        margin-bottom: 50px;
    }
`;

export const StyledForegroundWrapper = styled(StyledForeground)`
    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIDDLE_200}
    ) {
        position: relative;
        height: 100%;
    }
`;

export const StyledCenteredLayout = styled(StyledLayout)`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StyledDescriptionParagraph = styled(StyledParagraph)`
    max-width: 680px;
    text-align: center;
`;

export function ClientNetworkStorageImage({
    className,
}: {
    className?: string;
}) {
    const { isDesktop, isDesktopMiddle200, isTabletMiddle100 } =
        useBreakpoints();
    let ClientNetworkStorageSvg = ClientNetworkStorageMobileSvg;

    if (isTabletMiddle100 || (isDesktop && !isDesktopMiddle200)) {
        ClientNetworkStorageSvg = ClientNetworkStorageTabletSvg;
    }
    if (isDesktopMiddle200) {
        ClientNetworkStorageSvg = ClientNetworkStorageDesktopSvg;
    }

    return <ClientNetworkStorageSvg className={className} />;
}

export const StyledClientNetworkStorageImage = styled(
    ClientNetworkStorageImage
)`
    width: 100%;
    height: fit-content;
    min-height: 600px;

    margin-left: -30px;
    margin-top: 50px;
    margin-bottom: -100px;

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIDDLE_200}
    ){
        margin-top: 70px;
        margin-bottom: 30px;
    }
`;
