/* eslint-disable max-len */
import { TLineData } from 'components/Terminal/Terminal';

export const terminal1LinesData: TLineData[] = [
    {
        text: '&gt;&gt;&gt; from arcticdb import <span class="blue">Arctic</span>',
        indents: 0,
    },
    {
        text: '&gt;&gt;&gt; lib = <span class="blue">Arctic</span>("<span class="orange">s3</span>").get_library(<span class="orange">"eq.prices"</span>)',
        indents: 0,
    },
    {
        text: '&gt;&gt;&gt; df = lib.read(<span class="orange">"PX_UNADJ"</span>).data',
        indents: 0,
    },
    {
        text: '&gt;&gt;&gt; len(df), len(df.columns)<span class="orange"></span>',
        indents: 0,
    },
    {
        text: '(43958345, 329660)<span class="orange"></span>',
        indents: 0,
    },
    {
        text: '&gt;&gt;&gt; lib.read(<span class="orange">"PX_UNADJ"</span>, date_range=(dt(<span class="green">2004</span>, <span class="green">3</span>, <span class="green">1</span>), dt(<span class="green">2004</span>, <span class="green">3</span>, <span class="green">1</span>, <span class="green">5</span>)), columns=columns).data',
        indents: 0,
    },
    {
        text: '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;MKT&nbsp;&nbsp;SEC_ID_36&nbsp;&nbsp;SEC_ID_37&nbsp;&nbsp;SEC_ID_38&nbsp;&nbsp;SEC_ID_39&nbsp;&nbsp;SEC_ID_40<span class="orange"></span>',
        indents: 0,
    },
    {
        text: '2004-03-01&nbsp;00:00:00&nbsp;&nbsp;EMEA&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;60<span class="orange"></span>',
        indents: 0,
    },
    {
        text: '2004-03-01&nbsp;01:00:00&nbsp;&nbsp;EMEA&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;26&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;49&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;14&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;68&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;39<span class="orange"></span>',
        indents: 0,
    },
    {
        text: '2004-03-01&nbsp;02:00:00&nbsp;&nbsp;EMEA&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;93&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;25&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;76<span class="orange"></span>',
        indents: 0,
    },
    {
        text: '2004-03-01&nbsp;03:00:00&nbsp;&nbsp;EMEA&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;51&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;42&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;77&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;97&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;74<span class="orange"></span>',
        indents: 0,
    },
    {
        text: '2004-03-01&nbsp;04:00:00&nbsp;&nbsp;EMEA&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;77&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;31&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;36&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;13&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;71<span class="orange"></span>',
        indents: 0,
    },
    {
        text: '2004-03-01&nbsp;05:00:00&nbsp;&nbsp;EMEA&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;57&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;63&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;54&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;88&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;77<span class="orange"></span>',
        indents: 0,
    },
    {
        text: '&gt;&gt;&gt; lib.write(<span class="orange">"PX_UNADJ"</span>, new_data)',
        indents: 0,
    },
    {
        text: 'VersionedItem(symbol=PX,library=eq.prices,data=&lt;class "NoneType"&gt;,version=0,metadata=None,host=s3)<span class="orange"></span>',
        indents: 0,
    },
];
