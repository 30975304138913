import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Typewriter, { TypewriterClass } from 'typewriter-effect';

import {
    StyledContentWrapper,
    StyledDot,
    StyledTopbar,
} from './satelliteComponents';

export type TLineData = {
    text: string;
    indents: number;
};

const StyledWrapper = styled.div`
    width: 100%;
    height: 188px;
    background-color: ${({ theme }) => theme.palette.gray['00']};
    border-radius: 8px 8px 12px 12px;
    padding: 2px;
    display: flex;
    flex-direction: column;

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.TABLET_MIN}
    ) {
        height: 270px;
        padding: 4px;
    }

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.TABLET_MIDDLE_200}
    ) {
        height: 330px;
    }

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIN}
    ) {
        height: 410px;
        padding: 6px;
    }

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIDDLE_100}
    ) {
        height: 520px;
    }
`;

const INDENT_SIZE = 32;

const createLine = ({ text, indents }: TLineData) => {
    const marginLeft = `${indents * INDENT_SIZE}px`;

    return `<span style="margin-left: ${marginLeft}">${text}</span><br />`;
};

const createTypewriter = (
    typewriter: TypewriterClass,
    linesData: TLineData[]
): TypewriterClass => {
    linesData.forEach((lineData) => {
        const line = createLine(lineData);

        if (line.includes('2004-03') || line.includes('MKT')) {
            typewriter.pasteString(line, null);
        } else {
            typewriter.typeString(line);
        }
    });

    return typewriter;
};

type TTerminal = {
    linesData: TLineData[];
    play?: boolean;
    isTerminalVisible: boolean;
};

export function Terminal({
    linesData,
    play = true,
    isTerminalVisible,
}: TTerminal) {
    const TEXT_DELAY_TIME = 0;

    const typewriterRef = useRef<TypewriterClass>();

    const assignTypewriterAndAutoplay = (typewriter: TypewriterClass) => {
        typewriterRef.current = createTypewriter(typewriter, linesData);
        if (play && isTerminalVisible) {
            typewriterRef.current.start();
        }
    };

    useEffect(() => {
        if (play && isTerminalVisible) {
            typewriterRef?.current?.start();
        } else {
            typewriterRef?.current?.stop();
        }
    }, [play, isTerminalVisible]);

    return (
        <StyledWrapper>
            <StyledTopbar>
                <StyledDot />
                <StyledDot />
                <StyledDot />
            </StyledTopbar>
            <StyledContentWrapper>
                <Typewriter
                    options={{
                        delay: TEXT_DELAY_TIME,
                    }}
                    onInit={assignTypewriterAndAutoplay}
                />
            </StyledContentWrapper>
        </StyledWrapper>
    );
}
