import styled from 'styled-components';

import { StyledLayout } from 'components/Layout';
import { StyledCarousel } from 'components/Carousel';

export const StyledImageWrapper = styled.div`
    margin-right: -110px;
    width: 100%;

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIDDLE_200}
    ) {
        width: 55%;
        margin-right: -55px;
    }

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MAX}
    ) {
        margin-right: -115px;
    }
`;

export const StyledAboutContentWrapper = styled.div`
    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIDDLE_200}
    ) {
        width: 100%;
    }
`;

export const StyledAboutLayout = styled(StyledLayout)`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StyledTerminalCarousel = styled(StyledCarousel)`
    line-height: 0;

    .slick-dots-bottom {
        bottom: -40px;
    }
`;

export const StyledCarouselWrapper = styled.div`
    box-shadow: 0px 1px 2px rgba(21, 70, 106, 0.04),
        0px 40px 20px -16px rgba(21, 70, 106, 0.08),
        0px 40px 140px -40px rgba(21, 70, 106, 0.4);
    position: relative;
    height: fit-content;
    border-radius: 8px 8px 12px 12px;
    width: 100%;

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIN}
    ) {
        min-width: 500px;
        max-width: 1064px;
    }
`;
