/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { InView } from 'react-intersection-observer';
import { theme } from 'styles/theme';

const StyledWrapper = styled.span`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 12px;
`;

const StyledWord = styled.span`
    color: ${({ theme }) => theme.palette.gray['00']};
    display: block;
    text-align: center;
`;

const StyledGradientWord = styled.span`
    font-weight: bold;
    background: ${({ theme }) => theme.gradient.orange};
    background-clip: text;
    -webkit-background-clip: text;

    ::selection {
        color: ${({ theme }) => theme.palette.gray['00']};
        -webkit-text-fill-color: ${({ theme }) => theme.palette.gray['00']};
    }
`;

const StyledWordNoGradient = styled.span`
    font-weight: bold;
    color: ${({ theme }) => theme.gradient.orange[70]};

    ::selection {
        color: ${({ theme }) => theme.palette.gray['00']};
        -webkit-text-fill-color: ${({ theme }) => theme.palette.gray['00']};
    }
`;

function isSafariLessThan16() {
    const userAgent = navigator.userAgent.toLowerCase();
    const safariIndex = userAgent.indexOf('safari');
    if (
        safariIndex > -1 &&
        userAgent.indexOf('chrome') === -1 &&
        userAgent.indexOf('chromium') === -1
    ) {
        const versionIndex = userAgent.indexOf('version/');
        const versionNumberAsString = userAgent.substring(
            versionIndex + 8,
            versionIndex + 10
        );
        // eslint-disable-next-line radix
        const versionNumber = parseInt(versionNumberAsString);
        return versionNumber < 16;
    }
    return false;
}

type TTypeWriterEffectProps = {
    initialText: string;
    animationFinished: () => void;
};

function TypeWriterEffect({
    initialText,
    animationFinished,
}: TTypeWriterEffectProps) {
    const [text, setText] = useState('');

    const SPEED = 25; // ms

    const useGradient = !isSafariLessThan16();

    useEffect(() => {
        const timeout = setTimeout(() => {
            setText(initialText.slice(0, text.length + 1));
        }, SPEED);

        if (text === initialText) {
            animationFinished();
        }

        return () => clearTimeout(timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text]);
    if (!useGradient) {
        return (
            <span style={{ color: theme.palette.orange[70] }}>
                <StyledWordNoGradient data-name="panel">
                    {text}
                </StyledWordNoGradient>
                <StyledWordNoGradient style={{ opacity: 0 }} data-name="panel">
                    {initialText.substring(text.length, initialText.length)}
                </StyledWordNoGradient>
            </span>
        );
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return (
        <span>
            <StyledGradientWord
                style={{ WebkitTextFillColor: 'transparent' }}
                data-name="panel"
            >
                {text}
            </StyledGradientWord>
            <StyledGradientWord style={{ opacity: 0 }} data-name="panel">
                {initialText.substring(text.length, initialText.length)}
            </StyledGradientWord>
        </span>
    );
}

export function AnimatedDataFrameSection() {
    const [startAnimation, setAnimationStart] = useState(false);
    const [animationFinished, setAnimationFinished] = useState<{
        [key: string]: boolean;
    }>({});

    const stopAnimation = () => {
        setAnimationStart(false);
        setAnimationFinished({});
    };

    return (
        <InView
            as="span"
            onChange={(inView) =>
                inView ? setAnimationStart(true) : stopAnimation()
            }
        >
            <StyledWrapper>
                <StyledWord>
                    ArcticDB is incredibly fast.&nbsp;
                    {startAnimation && (
                        <TypeWriterEffect
                            initialText="Processes billions of rows in seconds."
                            animationFinished={() =>
                                setAnimationFinished((prevState) => ({
                                    ...prevState,
                                    animationOne: true,
                                }))
                            }
                        />
                    )}
                </StyledWord>
                <StyledWord>
                    ArcticDB transforms your ability to handle complex
                    real-world data.&nbsp;
                    {startAnimation && animationFinished?.animationOne && (
                        <TypeWriterEffect
                            initialText="Proven petabyte-scale."
                            animationFinished={() =>
                                setAnimationFinished((prevState) => ({
                                    ...prevState,
                                    animationTwo: true,
                                }))
                            }
                        />
                    )}
                    {startAnimation &&
                        animationFinished.hasOwnProperty('animationOne') ===
                            false && (
                            <StyledGradientWord
                                style={{ opacity: 0 }}
                                data-name="panel"
                            >
                                Proven petabyte-scale.
                            </StyledGradientWord>
                        )}
                </StyledWord>
                <StyledWord>
                    ArcticDB is built for the Python data science
                    ecosystem.&nbsp;
                    {startAnimation && animationFinished?.animationTwo && (
                        <TypeWriterEffect
                            initialText="DataFrames in, DataFrames out."
                            animationFinished={() =>
                                setAnimationFinished((prevState) => ({
                                    ...prevState,
                                    animationThree: true,
                                }))
                            }
                        />
                    )}
                    {startAnimation &&
                        animationFinished.hasOwnProperty('animationTwo') ===
                            false && (
                            <StyledGradientWord
                                style={{ opacity: 0 }}
                                data-name="panel"
                            >
                                DataFrames in, DataFrames out.
                            </StyledGradientWord>
                        )}
                </StyledWord>
            </StyledWrapper>
        </InView>
    );
}
