export interface IPricingCardContent {
    id: string;
    title: string;
    content: IPricingCardContentOption[];
    suggestion?: string;
    buttonText: string;
}

export interface IPricingCardContentOption {
    id: string;
    content: string;
    type: IPricingCardContentOptionType;
}

export type IPricingCardContentOptionType =
    | 'included'
    | 'excluded'
    | 'quote'
    | 'none';

export const pricingCardsContent: IPricingCardContent[] = [
    {
        id: 'OpenSource',
        title: 'Open Source',
        content: [
            { id: '0', content: 'Github Library', type: 'included' },
            { id: '1', content: 'Production Use', type: 'excluded' },
            { id: '2', content: 'Community Support', type: 'included' },
            {
                id: '3',
                content: '- Contact us for Academic License',
                type: 'quote',
            },
        ],
        suggestion:
            'Best suited to individuals, academics and for trying out ArcticDB',
        buttonText: 'Free',
    },
    {
        id: 'Pro',
        title: 'Pro',
        content: [
            { id: '0', content: 'Github Library', type: 'included' },
            { id: '1', content: 'Production Use', type: 'included' },
            { id: '2', content: 'Dedicated slack support', type: 'included' },
            { id: '3', content: 'Production Support', type: 'excluded' },
            { id: '4', content: 'Access To Roadmap', type: 'excluded' },
        ],
        suggestion: 'Best suited for small deployments, teams and individuals',
        buttonText: 'Contact us',
    },
    {
        id: 'Enterprise',
        title: 'Enterprise',
        content: [
            { id: '0', content: 'Github Library', type: 'included' },
            { id: '1', content: 'Production Use', type: 'included' },
            { id: '2', content: 'Dedicated slack support', type: 'included' },
            { id: '3', content: 'Production Support', type: 'included' },
            { id: '4', content: 'Access To Roadmap', type: 'included' },
        ],
        suggestion:
            'Best suited for larger deployments, many users, multiple use cases or organisation wide use',
        buttonText: 'Contact Us',
    },
    {
        id: 'AddOns',
        title: 'Add ons',
        content: [
            {
                id: '0',
                content: 'TickCollector',
                type: 'none',
            },
            {
                id: '1',
                content: 'Enterprise Toolkit',
                type: 'none',
            },
            {
                id: '2',
                content: 'Production Support (SLAs)',
                type: 'none',
            },
            {
                id: '3',
                content: 'New Feature Development',
                type: 'none',
            },
            {
                id: '4',
                content: 'Consultancy',
                type: 'none',
            },
            {
                id: '5',
                content: 'Custom SLAs',
                type: 'none',
            },
        ],
        suggestion:
            'Add ons available to purchase with Pro and Enterprise licences',
        buttonText: 'Contact Us',
    },
];
