import React from 'react';

import { Header } from 'components/Header';
import { StyledLayout } from 'components/Layout';
import { Footer } from 'components/Footer';
import { StyledForeground } from 'components/Foreground';
import { StyledCookieNotification } from 'components/CookieNotification';
import {
    StyledHeaderAndWelcomeBackground,
    StyledUniquelyScalableAndUnleashYourDataBackground,
} from './satelliteComponents';
import { AboutSection } from './Sections/AboutSection';
import { WelcomeSection } from './Sections/WelcomeSection';
import { Bloomberg } from './Sections/OpenSourceAndBloomberg';
import { UnleashYourData } from './Sections/UnleashYourData';
import { UniquelyScalable } from './Sections/UniquelyScalable';
import { PricingSection } from './Sections/PricingSection';
import { CTOQuoteSection } from './Sections/CTOQuoteSection';
import { FullyOwnYourData } from './Sections/FullyOwnYourData';

type TLandingPageProps = {
    handleOpenModal: (content: number) => void;
};

export function LandingPage({ handleOpenModal }: TLandingPageProps) {
    return (
        <>
            <StyledHeaderAndWelcomeBackground>
                <StyledLayout>
                    <StyledForeground>
                        <Header handleOpenModal={handleOpenModal} />
                        <WelcomeSection handleOpenModal={handleOpenModal} />
                    </StyledForeground>
                </StyledLayout>
            </StyledHeaderAndWelcomeBackground>
            <AboutSection />
            <CTOQuoteSection />
            <StyledUniquelyScalableAndUnleashYourDataBackground>
                <Bloomberg />
                <UniquelyScalable />
                <FullyOwnYourData />
                <UnleashYourData />
            </StyledUniquelyScalableAndUnleashYourDataBackground>
            <PricingSection handleOpenModal={handleOpenModal} />
            <Footer handleOpenModal={handleOpenModal} />
            <StyledCookieNotification />
        </>
    );
}
