import React from 'react';
import styled, { css } from 'styled-components';

import { StyledTitle } from 'components/Title';
import { StyledParagraph } from 'components/Paragraph';
import FlowDiagramDesktop from 'images/flowDiagramDesktop.svg';

export const StyledWelcomeSection = styled.section`
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding-block: 100px;
    height: 570px;

    @media screen and (max-width: 1300px) {
        flex-direction: column;
        justify-content: center;
        gap: 10px;

        align-items: center;
        height: auto;
    }
`;

export const LeftContent = styled.div``;

export const RightContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 1300px) {
        width: 100%;
        margin-left: 0;
    }

    @media screen and (min-width: 1300px) {
        margin-left: 60px;
    }
`;

export const ButtonAndLinkWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 1300px) {
        justify-content: center;
    }
`;

export const FindTheRightProductBtn = styled.button`
    border: 0;
    border-bottom: 1px solid #003e6b;
    padding: 0;
    margin-left: 20px;
    background: inherit;
    color: #003e6b;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    cursor: pointer;

    display: flex;
    align-items: center;
`;

export const StyledNavigation = styled.nav`
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: space-between;
`;

export const StyledMainTitle = styled(StyledTitle)`
    display: flex;
    flex-wrap: wrap;
    color: ${({ theme }) => theme.palette.blue['90']};

    font-size: 52px;
    font-weight: 700;
    line-height: 70px;

    @media screen and (max-width: 690px) {
        font-size: 50px;
        span {
            text-align: center;
        }
    }

    @media screen and (max-width: 1300px) {
        justify-content: center;
    }
`;

export const StyledMainTitleAnnotation = styled(StyledParagraph)`
    max-width: 650px;
    font-size: 24px;
    line-height: 151.182%;
    margin-top: 24px;

    & > span {
        font-weight: 600;
    }

    @media screen and (max-width: 1300px) {
        max-width: 100%;
        text-align: center;
    }
`;

export const StyledWrappedLine = styled.div`
    flex-basis: 100%;
`;

function FlowDiagramImage({ className }: { className?: string }) {
    return <FlowDiagramDesktop className={className} />;
}

export const StyledFlowDiagramImage = styled(FlowDiagramImage)`
    min-height: 145px;

    @media screen and (max-width: 1300px) {
        max-width: 100%;
    }
`;

export const IconsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: auto;
`;

export const Icons = styled.div`
    margin-top: 20px;
`;

export const ModalStyles = () => css`
    padding: 0 !important;
`;

export const IconWrapperStyles = () => css`
    top: 16px;
`;
