import React, { useState } from 'react';
import styled from 'styled-components';
import { InView } from 'react-intersection-observer';

import {
    DottedBackground,
    PatternPositions,
    PatternVariants,
} from 'components/DottedBackground';
import { StyledFastFamiliarFlexibleBoxes } from 'components/FastFamiliarFlexibleBoxes';
import { FancyHighlight } from 'components/FancyHighlight';
import { useBreakpoints } from 'hooks';
import { StyledTitleWrapper } from 'templates/LandingPage/satelliteComponents';
import { ParagraphTypes } from 'components/Paragraph';
import {
    StyledCenteredLayout,
    StyledClientNetworkStorageImage,
    StyledDescriptionParagraph,
    StyledEdgeWrapper,
    StyledForegroundWrapper,
} from './satelliteComponents';

const StyledUniquelyScalable = styled.section`
    position: relative;
    background: radial-gradient(
        323.69% 63.12% at -244.53% 62.91%,
        #acc9e5 0%,
        rgba(172, 201, 229, 0) 100%
    );
`;

export function UniquelyScalable() {
    const { isDesktopMiddle200 } = useBreakpoints();

    const [areBoxesVisible, setAreBoxesVisible] = useState<boolean>(false);

    return (
        <StyledUniquelyScalable>
            <StyledCenteredLayout>
                <StyledEdgeWrapper>
                    <InView
                        as="div"
                        onChange={(inView) =>
                            inView
                                ? setAreBoxesVisible(true)
                                : setAreBoxesVisible(false)
                        }
                    >
                        {isDesktopMiddle200 ? (
                            <>
                                <DottedBackground
                                    placements={{
                                        [PatternPositions.BOTTOM_RIGHT]:
                                            PatternVariants.DARK_BG,
                                    }}
                                />
                                <StyledForegroundWrapper>
                                    <StyledFastFamiliarFlexibleBoxes
                                        areBoxesVisible={areBoxesVisible}
                                    />
                                </StyledForegroundWrapper>
                            </>
                        ) : (
                            <StyledFastFamiliarFlexibleBoxes
                                areBoxesVisible={areBoxesVisible}
                            />
                        )}
                    </InView>
                </StyledEdgeWrapper>
                <StyledTitleWrapper>
                    <FancyHighlight animated fancy>
                        No Servers Needed
                    </FancyHighlight>
                </StyledTitleWrapper>
                <StyledDescriptionParagraph variant={ParagraphTypes.T20}>
                    ArcticDB is a columnar datastore optimized for
                    time-series data. As a client-side library, ArcticDB enables
                    a scale-out architecture which takes advantage of modern,
                    cost effective S3 storage.
                </StyledDescriptionParagraph>
                <StyledClientNetworkStorageImage />
            </StyledCenteredLayout>
        </StyledUniquelyScalable>
    );
}
