import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import FamiliarIcon from 'images/familiar.svg';
import FlexibleIcon from 'images/flexible.svg';
import { useBreakpoints } from 'hooks';
import { TBoxItem } from 'types';
import {
    StyledFastIcon,
    StyledDivider,
    StyledBoxWrapper,
} from './satelliteComponents';
import { Box } from './Box';

type TDivider = {
    divider: boolean;
};

type TFastFamiliarFlexibleBoxItem = TBoxItem & TDivider;

const boxItems: TFastFamiliarFlexibleBoxItem[] = [
    {
        id: 0,
        title: 'Fast',
        Icon: StyledFastIcon,
        divider: false,
        bulletPoints: [
            {
                id: 0,
                text: 'Processes billions of rows in seconds',
            },
            { id: 1, text: 'Scales from notebook to cluster' },
            { id: 2, text: 'Rapid prototyping of research ideas' },
        ],
    },
    {
        id: 1,
        title: 'Flexible',
        Icon: FlexibleIcon,
        divider: true,
        bulletPoints: [
            {
                id: 0,
                text: 'NoSQL for DataFrames',
            },
            {
                id: 1,
                text: 'Evolving schemas and changing columns over time',
            },
            {
                id: 2,
                text: 'Safely modify data with built-in versions',
            },
        ],
    },
    {
        id: 2,
        title: 'Familiar',
        Icon: FamiliarIcon,
        divider: true,
        bulletPoints: [
            { id: 0, text: 'Speaks Pandas' },
            {
                id: 1,
                text: 'Seamless integration with Python data science tools',
            },
            { id: 2, text: 'Easy install: pip install arcticdb' },
        ],
    },
];

type TFastFamiliarFlexibleProps = {
    areBoxesVisible: boolean;
};

const StyledContainerForBoxes = styled.div`
    position: absolute;
    margin-top: 0;
    display: flex;
    justify-content: center;
    right: 0; // prevents alliging left when position: absolute is set
    left: 0; // prevents alliging left when position: absolute is set
`;

function FastFamiliarFlexible({ areBoxesVisible }: TFastFamiliarFlexibleProps) {
    const { isDesktopMiddle200 } = useBreakpoints();
    const [playAnimation, setPlayAnimation] = useState(true);

    useEffect(() => {
        const HOVERED_BOXES = document.querySelector(
            '[data-name="hovered-boxes"]'
        );

        function hideAnimation() {
            setPlayAnimation(false);
        }
        function showAnimation() {
            setPlayAnimation(true);
        }

        HOVERED_BOXES?.addEventListener('mouseover', hideAnimation);
        HOVERED_BOXES?.addEventListener('mouseout', showAnimation);

        return () => {
            HOVERED_BOXES?.removeEventListener('mouseover', hideAnimation);
            HOVERED_BOXES?.addEventListener('mouseout', showAnimation);
        };
    }, []);

    const animatedBoxes = boxItems.map(
        ({ title, Icon, bulletPoints, divider, id }) => (
            <StyledBoxWrapper key={id}>
                {isDesktopMiddle200 && divider && <StyledDivider />}
                <Box
                    title={title}
                    Icon={Icon}
                    bulletPoints={bulletPoints}
                    id={id}
                    areBoxesVisible={areBoxesVisible}
                    playAnimation={playAnimation}
                    isAnimated
                />
            </StyledBoxWrapper>
        )
    );

    const hoveredBoxes = boxItems.map(
        ({ title, Icon, bulletPoints, divider, id }) => (
            <StyledBoxWrapper key={`second${id}`}>
                {isDesktopMiddle200 && divider && <StyledDivider />}
                <Box
                    title={title}
                    Icon={Icon}
                    bulletPoints={bulletPoints}
                    id={id}
                    areBoxesVisible={areBoxesVisible}
                    playAnimation={playAnimation}
                />
            </StyledBoxWrapper>
        )
    );

    const wrappedBoxes = (
        <>
            <StyledContainerForBoxes>{animatedBoxes}</StyledContainerForBoxes>
            <StyledContainerForBoxes data-name="hovered-boxes">
                {hoveredBoxes}
            </StyledContainerForBoxes>
        </>
    );

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{isDesktopMiddle200 ? wrappedBoxes : animatedBoxes}</>;
}

export const StyledFastFamiliarFlexibleBoxes = styled(FastFamiliarFlexible)`
    display: flex;
    flex-direction: column;
`;
