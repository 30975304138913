import styled from 'styled-components';

import { StyledParagraph } from 'components/Paragraph';

export const StyledBulletList = styled.ul`
    margin-top: 28px;
    padding-left: 16px;
    margin-bottom: 0;
`;

export const StyledListItem = styled.li`
    padding-top: 12px;

    &:first-of-type {
        padding-top: 0;
    }

    &::marker {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        color: ${({ theme }) => theme.palette.blue[80]};
    }
`;

export const StyledItemText = styled(StyledParagraph)`
    color: ${({ theme }) => theme.palette.gray[60]};
`;
