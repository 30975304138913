import React, { useEffect, useState } from 'react';
import '@fortawesome/fontawesome-free/css/all.css';
import axios from 'axios';
import { StaticImage } from 'gatsby-plugin-image';
import { useBreakpoints } from 'hooks';
import { ParagraphTypes } from 'components/Paragraph';
import { FancyHighlight } from 'components/FancyHighlight';
import { FancyHighlightVariants } from 'components/FancyHighlight/FancyHighlight';
import GithubIcon from 'images/github_blue_larger.svg';
import PypiIcon from 'images/pypi_resized.svg';
import { StyledButton } from 'components/Button';
import { ModalContents } from 'enums';
import { HandleOpenModalFunction } from 'pages';
import {
    ARCTIC_GITHUB,
    ARCTIC_GITHUB_API,
    ARCTIC_PYPI,
    ARCTIC_CONDA,
} from '../../../../consts';
import {
    LeftContent,
    RightContent,
    StyledFlowDiagramImage,
    StyledMainTitle,
    StyledMainTitleAnnotation,
    StyledWelcomeSection,
    IconsWrapper,
    Icons,
    ButtonAndLinkWrapper,
    FindTheRightProductBtn,
    IconWrapperStyles,
    ModalStyles,
} from './satelliteComponents';

function CondaIcon() {
    return (
        <StaticImage
            src="../../../../images/conda-forge.png"
            alt="conda-forge"
            placeholder="blurred"
            height={45}
        />
    );
}

export interface WelcomeSectionsProps {
    handleOpenModal: HandleOpenModalFunction;
}

export function WelcomeSection({ handleOpenModal }: WelcomeSectionsProps) {
    const { isTablet } = useBreakpoints();
    const [stargazers, setStargazers] = useState<string>();
    const [forks, setForks] = useState<string>();

    useEffect(() => {
        (async () => {
            const resp = await axios.get(ARCTIC_GITHUB_API);
            const stargazersCount = resp.data.stargazers_count;
            const forksCount = resp.data.forks_count;

            setStargazers(
                stargazersCount > 1000
                    ? `${(stargazersCount / 1000).toFixed(1)}k`
                    : `${stargazersCount}`
            );
            setForks(
                forksCount > 1000
                    ? `${(forksCount / 1000).toFixed(1)}k`
                    : `${forksCount}`
            );
        })();
    }, []);

    const scrollToElement = () => {
        const element = document.getElementById('pricingSection');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <StyledWelcomeSection style={{ zIndex: -1 }}>
            <LeftContent>
                <StyledMainTitle>
                    <span style={{ fontSize: '3.25rem', whiteSpace: 'nowrap' }}>
                        ArcticDB is a&nbsp;
                    </span>
                    <FancyHighlight
                        fancy
                        animated
                        variant={FancyHighlightVariants.GRADIENT}
                    >
                        <span style={{ fontSize: '3.25rem' }}>
                            DataFrame Database
                        </span>
                    </FancyHighlight>
                </StyledMainTitle>
                <StyledMainTitleAnnotation
                    variant={isTablet ? ParagraphTypes.T18 : ParagraphTypes.T24}
                >
                    Built for the modern <span>Python Data Science </span>
                    ecosystem, <span>ArcticDB </span>
                    transforms your ability to handle complex real world data
                    with Incredibly fast <span>proven Petabyte scale.</span>
                </StyledMainTitleAnnotation>
                <ButtonAndLinkWrapper>
                    <StyledButton
                        style={{
                            width: '119px',
                            height: '44px',
                            padding: '10px 16px',
                            borderRadius: '3px',
                            color: '#fff',
                            backgroundColor: '#3B81BA',
                            fontWeight: 600,
                            border: 0,
                        }}
                        onClick={() =>
                            handleOpenModal(ModalContents.GETARCTICDB, {
                                contentWrapper: ModalStyles,
                                iconWrapper: IconWrapperStyles,
                            })
                        }
                    >
                        Get ArcticDB
                    </StyledButton>
                    <FindTheRightProductBtn
                        type="button"
                        onClick={scrollToElement}
                    >
                        <span>Find the right product</span>
                        <StaticImage
                            src="../../../../images/arrow-right.svg"
                            alt="arrow right"
                        />
                    </FindTheRightProductBtn>
                </ButtonAndLinkWrapper>
            </LeftContent>
            <RightContent>
                <StyledFlowDiagramImage />
                <IconsWrapper>
                    <Icons>
                        <a href={ARCTIC_GITHUB}>
                            <GithubIcon
                                style={{
                                    width: '45px',
                                    height: '45px',
                                }}
                            />
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href={ARCTIC_PYPI}>
                            <PypiIcon style={{ height: '45px' }} />
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href={ARCTIC_CONDA}>
                            <CondaIcon />
                        </a>
                    </Icons>
                    <Icons>
                        <div
                            className="github-button"
                            style={{ marginRight: '10px' }}
                        >
                            <a href={ARCTIC_GITHUB}>
                                <i className="far fa-star" />
                                Star
                            </a>
                            <a
                                href={`${ARCTIC_GITHUB}/stargazers/`}
                                id="stargazers"
                            >
                                {stargazers ?? <i className="fas fa-sync" />}
                            </a>
                        </div>
                        <div className="github-button">
                            <a href={`${ARCTIC_GITHUB}/fork`}>
                                <i className="fas fa-code-branch" />
                                Fork
                            </a>
                            <a href={`${ARCTIC_GITHUB}/fork`} id="forks">
                                {forks ?? <i className="fas fa-sync" />}
                            </a>
                        </div>
                    </Icons>
                </IconsWrapper>
            </RightContent>
        </StyledWelcomeSection>
    );
}
