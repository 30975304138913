import React, { useState } from 'react';
import { graphql } from 'gatsby';

import { useToggle } from 'hooks';
import { LandingPage } from 'templates/LandingPage';
import { Modal, ModalCustomStyles } from 'components/Modal';
import { ConfirmationModalContent } from 'templates/ConfirmationModalContent';
import { ContactModalContent } from 'templates/ContactModalContent';
import { CommunityModalContent } from 'templates/CommunityModalContent';
import { SlackSignUpModalContent } from 'templates/SlackSignUpModalContent';
import { ThemedPage } from 'templates/Page';
import { ModalContents } from 'enums';
import { GetArcticDBModalContent } from 'templates/GetArcticDBModalContent';

export type HandleOpenModalFunction = (
    content: ModalContents,
    customStyles?: ModalCustomStyles,
    closeIconCustom?: React.ReactNode
) => void;

function IndexPage() {
    const [isModalOpened, toggleIsModalOpened] = useToggle(false);
    const [modalContent, setModalContent] = useState<ModalContents>(
        ModalContents.CONTACTUS
    );
    const [modalCustomStyles, setModalCustomStyles] =
        useState<ModalCustomStyles>();
    const [modalCloseIcon, setModalCLoseIcon] = useState<React.ReactNode>();

    const setIsBodyScrollable = (shouldBeScrollable: boolean) => {
        // https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open
        if (shouldBeScrollable) {
            const scrollY = document.body.style.top;
            document.body.style.position = '';
            document.body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
        } else {
            const { scrollY } = window;
            document.body.style.position = 'fixed';
            document.body.style.top = `-${scrollY}px`;
        }
    };

    const handleOpenModal = (
        content: ModalContents,
        customStyles?: ModalCustomStyles,
        closeIconCustom?: React.ReactNode
    ) => {
        setIsBodyScrollable(false);
        setModalContent(content);
        setModalCustomStyles(customStyles);
        setModalCLoseIcon(closeIconCustom);
        if (!isModalOpened) {
            toggleIsModalOpened();
        }
    };

    return (
        <ThemedPage handleOpenModal={handleOpenModal}>
            <LandingPage handleOpenModal={handleOpenModal} />
            {isModalOpened && (
                <Modal
                    visible={isModalOpened}
                    handleClose={() => {
                        toggleIsModalOpened();
                        setIsBodyScrollable(true);
                    }}
                    customStyles={modalCustomStyles}
                    closeIconCustom={modalCloseIcon}
                >
                    {modalContent === ModalContents.CONTACTUS && (
                        <ContactModalContent
                            handleOpenModal={handleOpenModal}
                        />
                    )}
                    {modalContent === ModalContents.COMMUNITY && (
                        <CommunityModalContent
                            handleOpenModal={handleOpenModal}
                        />
                    )}
                    {modalContent === ModalContents.CONFIRMATION && (
                        <ConfirmationModalContent />
                    )}
                    {modalContent === ModalContents.SLACKSIGNUP && (
                        <SlackSignUpModalContent />
                    )}
                    {modalContent === ModalContents.GETARCTICDB && (
                        <GetArcticDBModalContent />
                    )}
                </Modal>
            )}
        </ThemedPage>
    );
}

export default IndexPage;

type THead = {
    data: {
        site: {
            siteMetadata: {
                title: string;
                description: string;
                siteUrl: string;
                robots: string;
            };
        };
    };
};

export function Head({ data }: THead) {
    return (
        <>
            <title>{data.site.siteMetadata.title}</title>
            <meta
                name="description"
                content={data.site.siteMetadata.description}
            />
            <meta name="robots" content={data.site.siteMetadata.robots} />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />
        </>
    );
}

export const query = graphql`
    query IndexPageQuery {
        site {
            siteMetadata {
                title
                description
                siteUrl
                robots
            }
        }
    }
`;
