import React from 'react';
import styled, { css } from 'styled-components';

import { HeadingTypes } from 'components/Title/Title';
import { BulletList } from 'components/BulletList';
import { TBulletItem } from 'components/BulletList/BulletList';
import {
    StyledHeader,
    StyledHeaderIconWrapper,
    StyledHeaderTitle,
} from './satelliteComponents';
import { BOX_SHADOW } from '../../../consts';

const ANIMATION_TIME_PER_BOX = 10.5; // seconds
const NUMBER_OF_BOXES = 3;
const ANIMATION_DELAY_PER_BOX = ANIMATION_TIME_PER_BOX / NUMBER_OF_BOXES;

type TStyledBox = {
    boxNumber: number;
    areBoxesVisible: boolean;
    playAnimation: boolean;
};

const StyledBox = styled.div<TStyledBox>`
    border-radius: 12px;
    padding: 32px 40px;
    background: ${({ theme }) => theme.palette.blue['10']};
    box-shadow: ${BOX_SHADOW};
    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIDDLE_200}
    ) {
        margin-top: 0;
        background: ${({ theme }) => theme.palette.blue['10']};
        box-shadow: none;
        padding: 48px;
        width: 100%;
        height: 172px;
		overflow: hidden;
        cursor: pointer;
        .box-bullet-list {
            opacity: 0; // Used instead on 'display: none' because it doesn't work in @keyframes
            list-style-type: square;
        }
        ${({
        areBoxesVisible,
        playAnimation,
        boxNumber,
    }: {
        areBoxesVisible: boolean;
        playAnimation: boolean;
        boxNumber: number;
    }) =>
        areBoxesVisible &&
        playAnimation &&
        css`
            @keyframes box-animation {
                0% {
                    box-shadow: none;
                    height: 172px;
                    transform: translateY(65px);
                }
                5% {
                    z-index: 100;
                    height: 305px;
                    box-shadow: ${BOX_SHADOW};
                    transform: translateY(-65px);
                    pointer-events: none;
                }
                25% {
                    z-index: 100;
                    height: 305px;
                    box-shadow: ${BOX_SHADOW};
                    transform: translateY(-65px);
                    pointer-events: none;
                }
                30% {
                    height: 172px;
                    box-shadow: none;
                    transform: translateY(0);
                }
            }
            animation: box-animation ${ANIMATION_TIME_PER_BOX}s
                ${ANIMATION_DELAY_PER_BOX * boxNumber}s 1;
            .box-bullet-list {
                @keyframes resize-panel-animation-box-bullet-list {
                    0% {
                        opacity: 0;
                    }
                    5% {
                        opacity: 100; // Used instead on 'display: block' because it doesn't work in @keyframes
                    }
                    25% {
                        opacity: 100;
                    }
                    30% {
                        opacity: 0;
                    }
                }
                animation: resize-panel-animation-box-bullet-list
                    ${ANIMATION_TIME_PER_BOX}s
                    ${ANIMATION_DELAY_PER_BOX * boxNumber}s infinite;
            }
        `}
    }
`;

type TBox = {
    title: string;
    Icon: React.ElementType;
    bulletPoints: TBulletItem[];
    id: number;
    areBoxesVisible: boolean;
    playAnimation: boolean;
    isAnimated?: boolean;
};

const StyledBoxWithoutAnimation = styled.div`
    border-radius: 12px;
    background: ${({ theme }) => theme.palette.blue['10']};
    box-shadow: ${BOX_SHADOW};
    transition: 100ms ease-out;
    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIDDLE_200}
    ) {
        margin-top: 0;
        background: ${({ theme }) => theme.palette.blue['10']};
        box-shadow: none;
        padding: 48px;
        width: 100%;
        height: 172px;
		overflow: hidden;
        cursor: pointer;
        .box-bullet-list {
            opacity: 0; // Used instead on 'display: none' because it doesn't work in @keyframes
            list-style-type: square;
        }
        &:hover {
            position: relative;
            background: ${({ theme }) => theme.palette.blue['10']};
            height: 305px;
            padding-bottom: 60px;
            box-shadow: ${BOX_SHADOW};
            transform: translateY(-65px);
            .box-title {
                color: ${({ theme }) => theme.palette.blue[100]};
            }
            .box-bullet-list {
                opacity: 100; // Used instead on 'display: block' because it doesn't work in @keyframes
                max-width: 280px;
                transition-duration: 0s;
            }
        }
    }
    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MAX}
    ) {
        &:hover {
            padding: 32px 48px;
            .box-bullet-list {
                margin-top: 28px;
            }
        }
    }
`;

export function Box({
    title,
    bulletPoints,
    Icon,
    id,
    areBoxesVisible,
    playAnimation,
    isAnimated = false,
}: TBox) {
    const boxContent = (
        <>
            <StyledHeader>
                <StyledHeaderIconWrapper>
                    <Icon />
                </StyledHeaderIconWrapper>
                <StyledHeaderTitle
                    className="box-title"
                    variant={HeadingTypes.H2}
                >
                    {title}
                </StyledHeaderTitle>
            </StyledHeader>
            <BulletList className="box-bullet-list" items={bulletPoints} />
        </>
    );

    return isAnimated ? (
        <StyledBox
            data-name="power-box"
            boxNumber={id}
            areBoxesVisible={areBoxesVisible}
            playAnimation={playAnimation}
        >
            {boxContent}
        </StyledBox>
    ) : (
        <StyledBoxWithoutAnimation data-name="power-box">
            {boxContent}
        </StyledBoxWithoutAnimation>
    );
}
