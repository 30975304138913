import React, { useState } from 'react';
import styled from 'styled-components';

import { Devices, useBreakpoints } from 'hooks';
import { ParagraphTypes, StyledParagraph } from 'components/Paragraph';
import {
    DottedBackground,
    PatternPositions,
    PatternVariants,
} from 'components/DottedBackground';
import { StyledForeground } from 'components/Foreground';
import { FancyHighlight } from 'components/FancyHighlight';
import { AnimatedDataFrameSection } from 'components/AnimatedDataFrameSection';
import { StyledTitleWrapper } from 'templates/LandingPage/satelliteComponents';
import { FancyHighlightVariants } from 'components/FancyHighlight/FancyHighlight';
import { Terminal } from 'components/Terminal';
import { terminal1LinesData } from 'components/Terminal/terminalData';
import { InView } from 'react-intersection-observer';
import {
    StyledAboutLayout,
    StyledTerminalCarousel,
    StyledCarouselWrapper,
} from './satelliteComponents';

const StyledAboutSection = styled.section`
    position: relative;
    padding-bottom: 150px;
    padding-top: 30px;
    background-color: ${({ theme }) => theme.palette.blue['90']};

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.TABLET_MIN}
    ) {
        padding-top: 60px;
        padding-bottom: 180px;
    }

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIN}
    ) {
        padding-top: 120px;
        padding-bottom: 200px;
    }
`;

const TERMINAL_INTERVAL_SECONDS = 30;
const TERMINAL_INTERVAL_MILLISECONDS = TERMINAL_INTERVAL_SECONDS * 1000;

const deviceToParagraphsBottomMarginMap = {
    [Devices.MOBILE]: 30,
    [Devices.TABLET]: 70,
    [Devices.DESKTOP]: 100,
};

export function AboutSection() {
    const { isTablet, device } = useBreakpoints();
    const [activeTerminalId, setActiveTerminalId] = useState<number>(0);
    const [isTerminalVisible, setIsTerminalVisible] = useState<boolean>(false);

    return (
        <StyledAboutSection>
            <DottedBackground
                placements={{
                    [PatternPositions.BOTTOM_LEFT]: PatternVariants.DARK_BG,
                    [PatternPositions.BOTTOM_RIGHT]: PatternVariants.DARK_BG,
                }}
            />
            <StyledForeground>
                <StyledAboutLayout>
                    <StyledTitleWrapper>
                        <FancyHighlight
                            fancy
                            animated
                            variant={FancyHighlightVariants.GRADIENT}
                        >
                            <span style={{ marginBottom: '40px' }}>
                                DataFrame Revolution
                            </span>
                        </FancyHighlight>
                    </StyledTitleWrapper>

                    <StyledParagraph
                        margin={`0 0 ${deviceToParagraphsBottomMarginMap[device]}px 0`}
                        variant={
                            isTablet ? ParagraphTypes.T20 : ParagraphTypes.T24
                        }
                    >
                        <AnimatedDataFrameSection />
                    </StyledParagraph>

                    <StyledCarouselWrapper>
                        <StyledTerminalCarousel
                            afterChange={setActiveTerminalId}
                            effect="fade"
                            autoplaySpeed={TERMINAL_INTERVAL_MILLISECONDS}
                            autoplay
                        >
                            <InView
                                as="div"
                                onChange={(inView) =>
                                    inView
                                        ? setIsTerminalVisible(true)
                                        : setIsTerminalVisible(false)
                                }
                            >
                                <Terminal
                                    linesData={terminal1LinesData}
                                    play={activeTerminalId === 0}
                                    isTerminalVisible={isTerminalVisible}
                                />
                            </InView>
                        </StyledTerminalCarousel>
                    </StyledCarouselWrapper>
                </StyledAboutLayout>
            </StyledForeground>
        </StyledAboutSection>
    );
}
