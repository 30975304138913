import React from 'react';

import {
    StyledBulletList,
    StyledListItem,
    StyledItemText,
} from './satelliteComponents';

export type TBulletItem = {
    id: number;
    text: string;
};

type TBulletList = {
    items: TBulletItem[];
    className?: string;
};

export function BulletList({ items, className }: TBulletList) {
    return (
        <StyledBulletList className={className}>
            {items.map(({ text, id }: TBulletItem) => (
                <StyledListItem key={id}>
                    <StyledItemText>{text}</StyledItemText>
                </StyledListItem>
            ))}
        </StyledBulletList>
    );
}
