import styled from 'styled-components';

import { StyledTitle } from 'components/Title';
import { Hamburger } from 'components/Hamburger';

export const StyledHamburger = styled(Hamburger)`
    position: absolute;
    top: 28px;
    right: 20px;

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.TABLET_MIN}
    ) {
        top: 32px;
        right: 32px;
    }
`;

export const StyledHeaderAndWelcomeBackground = styled.div`
    /* position: relative;
    z-index: 1; */
    background: radial-gradient(
            198% 81.06% at 159.87% 0%,
            ${({ theme }) => theme.palette.blue['30']} 0%,
            rgba(172, 201, 229, 0) 100%
        ),
        radial-gradient(
            274.44% 152.86% at -154.8% 161.02%,
            ${({ theme }) => theme.palette.orange['70']} 20.31%,
            rgba(218, 168, 42, 0) 100%
        );

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.TABLET_MIN}
    ) {
        background: radial-gradient(
            198% 81.06% at 159.87% 0%,
            ${({ theme }) => theme.palette.blue['30']} 0%,
            rgba(172, 201, 229, 0) 100%
        ),
        radial-gradient(
            274.44% 152.86% at -154.8% 161.02%,
            ${({ theme }) => theme.palette.orange['70']} 20.31%,
            rgba(218, 168, 42, 0) 100%
        );
    }

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIN}
    ) {
        background: radial-gradient(
            44.41% 90.11% at 100% 64.13%,
            ${({ theme }) => theme.palette.blue['30']} 0%,
            rgba(172, 201, 229, 0) 100%
        ),
        radial-gradient(
            154.34% 219.09% at -54.34% 204.53%,
            ${({ theme }) => theme.palette.orange['70']} 20.31%,
            rgba(218, 168, 42, 0) 100%
        );

    }
`;

export const StyledUniquelyScalableAndUnleashYourDataBackground = styled.div`
    background: radial-gradient(
            451.73% 143.13% at 425.33% -74.36%,
            ${({ theme }) => theme.palette.orange['70']} 27.08%,
            rgba(218, 168, 42, 0) 100%
        ),
        radial-gradient(
            327.6% 72.47% at -239.73% 105.45%,
            ${({ theme }) => theme.palette.blue['30']} 0%,
            rgba(172, 201, 229, 0) 100%
        );

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.TABLET_MIN}
    ) {
        background: radial-gradient(
            451.73% 143.13% at 425.33% -74.36%,
            ${({ theme }) => theme.palette.orange['70']} 27.08%,
            rgba(218, 168, 42, 0) 100%
        ),
        radial-gradient(
            327.6% 72.47% at -239.73% 105.45%,
            ${({ theme }) => theme.palette.blue['30']} 0%,
            rgba(172, 201, 229, 0) 100%
        );
    }

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIN}
    ) {
        background: radial-gradient(
            152.97% 143.49% at 151.53% -100.46%,
            ${({ theme }) => theme.palette.orange['70']} 27.08%,
            rgba(218, 168, 42, 0) 100%
        ),
        radial-gradient(
            54.69% 78.45% at -17.62% 54.42%,
            ${({ theme }) => theme.palette.blue['30']} 0%,
            rgba(172, 201, 229, 0) 100%
        );

    }
`;

export const StyledTitleWrapper = styled(StyledTitle)`
    margin-bottom: 50px;

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIN}
    ) {
        font-size: 48px;
        line-height: 72px;
    }
`;

export const StyledSecondTitleWrapper = styled(StyledTitle)`
    margin: 70px 0 50px;

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIN}
    ) {
        font-size: 48px;
        line-height: 72px;
    }
`;
