import React from 'react';
import CookieConsent from 'react-cookie-consent';
import styled from 'styled-components';

type TCookieConsent = {
    className?: string;
};

function CookieNotification({ className }: TCookieConsent) {
    return (
        <CookieConsent
            location="bottom"
            buttonText="OK"
            cookieName="arcticDBSite"
            containerClasses={className}
            expires={150}
        >
            This website uses cookies to enhance the user experience.
        </CookieConsent>
    );
}

export const StyledCookieNotification = styled(CookieNotification)`
    // !important used due to updating 'style' attribute
    background: ${({ theme }) => theme.palette.blue[10]} !important;
    border-top-color: ${({ theme }) => theme.palette.gray['70']};
    border-top-style: solid;
    border-top-width: 1px;
    color: ${({ theme }) => theme.palette.gray['70']} !important;
    display: flex;
    justify-content: center !important;

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIN}
    ) {
        justify-content: space-between !important;
    }

    #rcc-confirm-button {
        background-color: ${({ theme }) => theme.palette.blue['80']} !important;
        color: ${({ theme }) => theme.palette.gray['00']} !important;
        border-radius: 4px !important;
        font-weight: 600 !important;
        margin: 20px !important;
    }
`;
