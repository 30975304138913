import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, InferType } from 'yup';
import Turnstile from 'react-turnstile';

import { backendService } from 'services';
import { StyledTitle } from 'components/Title';
import { HeadingTypes } from 'components/Title/Title';
import { StyledButton } from 'components/Button';
import { StyledParagraph, ParagraphTypes } from 'components/Paragraph';
import { ControlledInput } from 'components/Input/ControlledInput';
import { StyledLink } from 'components/Link';
import { TColor } from 'types';
import { TTheme } from 'styles/theme';
import { useBreakpoints } from 'hooks';
import { PRIVACY_POLICY_URL } from 'consts/externalLinks';
import {
    StyledBottomSection,
    StyledUpperSection,
    StyledForm,
    StyledAnnotation,
} from '../common';

const blueColor: TColor = ({ theme }: { theme: TTheme }) =>
    theme.palette.blue['40'];

const StyledSlackSignUpModalContent = styled.div`
    height: auto;
    background-color: ${({ theme }) => theme.palette.gray['00']};
`;

const schema = object({
    fullName: string().required('Full name is a required field'),
    organisation: string(),
    emailAddress: string()
        .email('Email address is invalid')
        .required('Email address is a required field'),
    jobRole: string(),
});

type TFormInputs = InferType<typeof schema>;

export function SlackSignUpModalContent() {
    const { isMobile } = useBreakpoints();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [slackSignUpStatus, setSlackSignUpStatus] = useState<
        string | undefined
    >(undefined);
    const [turnstileToken, setTurnstileToken] = useState<string>('');

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<TFormInputs>({
        resolver: yupResolver(schema),
    });

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const onSubmit: SubmitHandler<TFormInputs> = async (formData) => {
        setIsSubmitting(true);

        try {
            const response = await backendService.submitSlackSignUp({
                name: formData.fullName,
                email: formData.emailAddress,
                companyName: formData.organisation,
                cfTurnstileResponse: turnstileToken,
            });

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setSlackSignUpStatus(response.data?.message);
            setIsSubmitting(false);
        } catch (err) {
            setSlackSignUpStatus(undefined);
            setError(JSON.stringify(err));
            setIsSubmitting(false);
        }
    };

    const slackSignUpFormContent = () => (
        <>
            <StyledTitle variant={HeadingTypes.H2}>
                Join our Slack channel
            </StyledTitle>
            <StyledParagraph
                textColor={({ theme }) => theme.palette.gray['60']}
                margin="28px 0 40px 0"
                variant={ParagraphTypes.T16}
            >
                Sign up below to receive an invitation to join the ArcticDB
                Slack community
            </StyledParagraph>
            <ControlledInput
                name="fullName"
                label="Full Name"
                type="text"
                control={control}
                errorMessage={errors.fullName?.message}
            />
            <ControlledInput
                name="organisation"
                label="Company"
                type="text"
                required={false}
                control={control}
            />
            <ControlledInput
                name="emailAddress"
                label="Email"
                type="email"
                control={control}
                errorMessage={errors.emailAddress?.message}
            />
            <Turnstile
                sitekey={process.env.GATSBY_TURNSTILE_SITEKEY as string}
                onVerify={(token) => setTurnstileToken(token)}
            />
            <StyledLink
                href={PRIVACY_POLICY_URL}
                textColor={blueColor}
                target="_blank"
                rel="noopener noreferrer"
            >
                Privacy Policy
            </StyledLink>
        </>
    );

    const slackSignUpSuccess = () => (
        <>
            <StyledTitle variant={HeadingTypes.H2}>
                Thank you for joining us on Slack!
            </StyledTitle>
            <StyledParagraph
                textColor={({ theme }) => theme.palette.gray['60']}
                margin="28px 0 40px 0"
                variant={ParagraphTypes.T16}
            >
                Thanks for signing up to our Slack community. Look out for an
                email inviting you to join!
            </StyledParagraph>
        </>
    );

    const slackSignUpError = () => (
        <>
            <StyledTitle variant={HeadingTypes.H2}>
                Error during submitting form
            </StyledTitle>
            <StyledParagraph
                textColor={({ theme }) => theme.palette.gray['60']}
                margin="28px 0 40px 0"
                variant={ParagraphTypes.T16}
            >
                Some error occured :( Try again!
            </StyledParagraph>
        </>
    );

    return (
        <StyledSlackSignUpModalContent>
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
                <StyledUpperSection>
                    {slackSignUpStatus && slackSignUpSuccess()}
                    {error && slackSignUpError()}
                    {!slackSignUpStatus && !error && slackSignUpFormContent()}
                </StyledUpperSection>
                {!slackSignUpStatus && !error && (
                    <StyledBottomSection>
                        <StyledAnnotation variant={ParagraphTypes.T14}>
                            Required fields
                        </StyledAnnotation>
                        <StyledButton
                            htmlType="submit"
                            small
                            loading={isSubmitting}
                            margin={`${isMobile ? 32 : 0}px 0 0 0`}
                        >
                            Submit
                        </StyledButton>
                    </StyledBottomSection>
                )}
            </StyledForm>
        </StyledSlackSignUpModalContent>
    );
}
