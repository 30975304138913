import styled from 'styled-components';

export const StyledWrapper = styled.div`
    margin: 16px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 36px;
`;

export const StyledInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
export const StyledInfo = styled.div`
    display: flex;
    align-items: center;
`;
export const StyledInfoIconContainer = styled.div`
    margin-right: 10px;
`;
