import React from 'react';
import styled from 'styled-components';

import { StyledForeground } from 'components/Foreground';
import { QuantitativeFinanceBoxes } from 'components/QuantitativeFinanceBoxes';
import { StyledLayout } from 'components/Layout';
import {
    DottedBackground,
    PatternPositions,
    PatternVariants,
} from 'components/DottedBackground';

const StyledUnleashYourData = styled.section`
    position: relative;
    background: radial-gradient(
        323.69% 63.12% at -244.53% 62.91%,
        #acc9e5 0%,
        rgba(172, 201, 229, 0) 100%
    );
`;

export function UnleashYourData() {
    return (
        <StyledUnleashYourData>
            <DottedBackground
                placements={{
                    [PatternPositions.BOTTOM_LEFT]: PatternVariants.LIGHT_BG,
                }}
            />
            <StyledForeground>
                <StyledLayout>
                    <QuantitativeFinanceBoxes />
                </StyledLayout>
            </StyledForeground>
        </StyledUnleashYourData>
    );
}
