import styled from 'styled-components';

import { StyledTitle } from 'components/Title';
import { StyledCarousel } from 'components/Carousel';
import UnleashYourDataIllustration from 'images/unleashYourDataIllustration.svg';
import { StyledInteractiveBox } from './Box';

export const StyledQuantitativeFinanceCarousel = styled(StyledCarousel)`
    margin-top: 28px;
    padding-bottom: 80px;

    .slick-slide {
        padding-left: 4px;
        padding-right: 4px;
    }

    .slick-dots-bottom {
        bottom: 35px;
    }

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.TABLET_MIN}
    ) {
        margin-top: 64px;
    }
`;

export const StyledCarouselItem = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 590px;
`;

export const StyledIllustration = styled(UnleashYourDataIllustration)`
    width: calc(100% - 40px);
    height: fit-content;

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.MOBILE_MAX}
    ) {
        min-width: 280px;
    }

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.TABLET_MIDDLE_100}
    ) {
        width: calc(100% - 100px);
        min-width: 300px;
        min-height: 300px;
    }

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIDDLE_200}
    ) {
        margin-left: -120px;
        width: 600px;
        min-height: 500px;
    }
`;

export const StyledInteractiveBoxWithMargins = styled(StyledInteractiveBox)`
    margin-bottom: 8px;
    min-height: auto;

    &:last-child {
        margin-bottom: 100px;
    }

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIDDLE_200}
    ) {
        margin-bottom: 0;

        &:last-child {
            margin-bottom: 50px;
        }
    }

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MAX}
    ) {
        &:last-child {
            margin-bottom: 100px;
        }
    }
`;

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 80px;

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.TABLET_MIN}
    ) {
        margin-top: 100px;
    }

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIDDLE_200}
    ) {
        flex-direction: row;
        margin-top: 0;
        column-gap: 60px;
    }

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MAX}
    ) {
        margin-top: 34px;
    }
`;

export const StyledTitleWrapped = styled(StyledTitle)`
    margin-bottom: 28px;

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.TABLET_MIN}
    ) {
        margin-bottom: 64px;
    }

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIN}
    ) {
        margin-bottom: 72px;
    }

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIN}
    ) {
        font-size: 48px;
        line-height: 72px;
    }
`;

export const StyledHalfSpace = styled.div`
    flex: 1;
`;
