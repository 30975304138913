import styled from 'styled-components';

const TypewriterWrapperClass = 'Typewriter__wrapper';
const TypewriterCursorClass = 'Typewriter__cursor';

export const StyledTopbar = styled.div`
    width: 100%;
    height: 11px;
    min-height: 11px;
    display: flex;
    align-items: center;
    padding-left: 4px;
    background-color: #eaf1f8;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.TABLET_MIN}
    ) {
        height: 24px;
        min-height: 24px;
        padding-left: 8px;
    }

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIN}
    ) {
        height: 36px;
        min-height: 36px;
        padding-left: 12px;
    }
`;

export const StyledDot = styled.div`
    width: 3px;
    min-width: 3px;
    height: 3px;
    min-height: 3px;
    background-color: ${({ theme }) => theme.palette.gray['30']};
    border-radius: 50%;
    margin-right: 1px;

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.TABLET_MIN}
    ) {
        width: 6px;
        min-width: 6px;
        height: 6px;
        min-height: 6px;
        margin-right: 2px;
    }

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIN}
    ) {
        width: 10px;
        min-width: 10px;
        height: 10px;
        min-height: 10px;
        margin-right: 4px;
    }
`;

export const StyledContentWrapper = styled.div`
    width: 100%;
    flex: 1;
    background-color: #0a1c29;
    padding: 8px 7px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .${TypewriterWrapperClass} {
        line-height: 0;

        span {
            color: ${({ theme }) => theme.palette.gray['00']};
            font-family: Roboto Mono;
            font-size: 4px;
            line-height: 8px;

            &.orange {
                color: #f3af64;
            }

            &.cyan {
                color: #20d3ba;
            }

            &.blue {
                color: #65abec;
            }

            &.pink {
                color: #e74686;
            }

            &.green {
                color: #00ff00;
            }
        }
    }

    .${TypewriterCursorClass} {
        background-color: ${({ theme }) => theme.palette.gray['00']};
        color: transparent;
        width: 1px;
        line-height: 4px;
        font-size: 4px;
        display: inline-block;
    }

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.TABLET_MIN}
    ) {
        padding: 16px;

        .${TypewriterWrapperClass} {
            line-height: 2vw;

            span {
                font-size: 1.8vw;
                line-height: 11px;
            }
        }

        .${TypewriterCursorClass} {
            width: 2px;
            line-height: 1vw;
            font-size: 1vw;
            margin-top: 6px;
        }
    }

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.TABLET_MIDDLE_200}
    ) {
        .${TypewriterWrapperClass} {
            line-height: 2.4vw;

            span {
                font-size: 1.3vw;
                line-height: 1.3vw;
            }
        }
    }

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIN}
    ) {
        padding: 24px;

        .${TypewriterWrapperClass} {
            line-height: 20px;

            span {
                font-size: 1.1vw;
                line-height: 1.1vw;
            }
        }

        .${TypewriterCursorClass} {
            width: 3px;
            line-height: 1.1vw;
            font-size: 1.1vw;
        }
    }

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIDDLE_100}
    ) {
        .${TypewriterWrapperClass} {
            line-height: 26px;

            span {
                font-size: 12px;
                line-height: 20px;
            }
        }

        .${TypewriterCursorClass} {
            font-size: 12px;
            line-height: 12px;
        }
    }

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIDDLE_300}
    ) {
        .${TypewriterWrapperClass} span {
            font-size: 16px;
        }

        .${TypewriterCursorClass} {
            font-size: 14px;
            line-height: 14px;
        }
    }
`;
