import { Carousel } from 'antd';
import styled from 'styled-components';

export const StyledCarousel = styled(Carousel)`
    .slick-dots {
        margin-bottom: 0;
        li {
            width: 12px;
            height: 12px;
            button {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: ${({ theme }) => theme.palette.blue[30]};
            }
        }
        li.slick-active {
            width: 12px;

            button {
                background: ${({ theme }) => theme.palette.blue[80]};
            }
        }
    }
`;
