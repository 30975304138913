import styled from 'styled-components';

export const StyledContentWrapper = styled.div`
    display: ;
    // display: flex;
    // justify-content: space-around;
    // flex-wrap: wrap;
    // padding: 0 8px;

    // // prettier-ignore
    // @media screen and (
    //     max-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIN}
    // ) {
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: center;
    // }
`;

export const StyledBoxContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    color: ${({ theme }) => theme.palette.blue['100']} // prettier-ignore
        @media screen and
        (max-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIN}) {

    }

    &:nth-child(2) {
        flex-direction: row-reverse;
    }
    // padding-bottom: 120px;
`;

export const StyledSubTitle = styled.div`
    padding: 12px 0;
    color: ${({ theme }) => theme.palette.blue['100']};
    font-weight: 700;
    font-size: 28px;
`;

export const StyledBloombergSection = styled.section`
    position: relative;
    background: radial-gradient(
        323.69% 63.12% at -244.53% 62.91%,
        #acc9e5 0%,
        rgba(172, 201, 229, 0) 100%
    );
    padding: 200px 0;
`;

export const StyledDescPhotoWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
`;

export const StyledDescriptionWrapper = styled.div`
    padding-top: 14px;
    width: 756px;
`;

export const StyledDescription = styled.p`
    font-size: 20px;
    paddint-top: 16px;
    font size: 20px;
    line height: 32px
`;

export const StyledWithoutImgWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 756px;
    align-items: space-around;
    justify-content: center;
    width: inherit;
`;

export const StyledImageWrapper = styled.div`
    max-width: 361px;
    max-height: 263px;
    padding: 32px 12px;

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIDDLE_100}
    ) {
        padding: 0 12px;
    }

    // prettier-ignore
    @media screen and (
        min-width: ${({ theme }) => theme.breakpoints.DESKTOP_MIDDLE_200}
    ) {
        padding: 0 12px;
    }

    .bloomberg_logo {
        display: inline;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .CTO {
        display: inline;
        width: 95%;
        height: 95%;
        object-fit: cover;
        padding: 0 10px;
    }
`;
