/* eslint-disable max-len */
import { StyledForeground } from 'components/Foreground';
import React from 'react';
import { StyledLayout } from 'components/Layout';
import { StaticImage } from 'gatsby-plugin-image';
import {
    StyledBoxContentWrapper,
    StyledWithoutImgWrapper,
    StyledDescPhotoWrapper,
    StyledDescriptionWrapper,
    StyledDescription,
    OrangeQuoteEnd,
    OrangeQuoteStart,
    StyledFigcaption,
    StyledText,
    StyledCTOQuoteSection,
} from './satelliteComponents';

const firstParagraphOfQuote =
    'ArcticDB has enhanced our ability to generate new trading strategies, optimise portfolios, and manage investment risk. It has transformed the way we handle data and we know others will gain great benefits from it too; from boutique hedge funds through to large traditional asset managers, global investment banks and beyond.';

const secondParagraphOfQuote =
    'We would like to see ArcticDB become ubiquitous, used to power every organisation where data is processed and insights extracted at scale.';

const CTOPhotoUrl = '../../../../images/GarryCollierCtoPhotoHD.jpg';
const CTOPhotoAlternativetext = 'Photo of Gary Collier - Man Group CTO';
const GarryCollier = 'Gary Collier, ';
const manGroupCto = 'Man Group CTO';

export function CTOQuoteSection() {
    return (
        <StyledCTOQuoteSection>
            <StyledForeground>
                <StyledLayout>
                    <StyledBoxContentWrapper>
                        <StyledWithoutImgWrapper>
                            <StyledDescPhotoWrapper>
                                <StyledDescriptionWrapper>
                                    <StyledDescription>
                                        <OrangeQuoteStart>“</OrangeQuoteStart>
                                        <StyledText>
                                            {firstParagraphOfQuote}
                                        </StyledText>
                                        <br />
                                        <StyledText>
                                            {secondParagraphOfQuote}
                                            <OrangeQuoteEnd>”</OrangeQuoteEnd>
                                        </StyledText>
                                    </StyledDescription>
                                </StyledDescriptionWrapper>
                            </StyledDescPhotoWrapper>
                        </StyledWithoutImgWrapper>
                        <figure>
                            <StaticImage
                                src={CTOPhotoUrl}
                                alt={CTOPhotoAlternativetext}
                                placeholder="blurred"
                                layout="fixed"
                                width={288}
                                height={288}
                                style={{
                                    marginBottom: '11px',
                                    borderRadius: '10px',
                                }}
                            />
                            <StyledFigcaption>
                                <span>{GarryCollier}</span>
                                <span>{manGroupCto}</span>
                            </StyledFigcaption>
                        </figure>
                    </StyledBoxContentWrapper>
                </StyledLayout>
            </StyledForeground>
        </StyledCTOQuoteSection>
    );
}
