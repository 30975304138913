import { StyledTitle } from 'components/Title';
import styled from 'styled-components';

export const StyledCTOQuoteSection = styled.section`
    position: relative;
    background: rgba(236, 236, 236, 0.3);
    padding-block: 100px;

    @media screen and (max-width: 1220px) {
        margin-top: 100px;
    }
`;

export const StyledBoxContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;

    @media screen and (max-width: 1220px) {
        flex-direction: column;
        gap: 10px;
    }
`;

export const StyledImageWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 1220px) {
        padding-inline: 100px;
        justify-content: center;
    }

    .fullyOwnYourDataImage {
        display: inline;
        min-width: 280px;
        height: 237px;

        @media screen and (max-width: 1220px) {
            min-width: 320px;
            min-height: 310px;
        }
    }
`;

export const StyledDescPhotoWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
`;

export const StyledDescriptionWrapper = styled.div`
    width: 100%;
    max-width: 600px;
`;

export const StyledDescription = styled.p`
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 1220px) {
        text-align: center;
        align-items: center;
    }
`;

export const StyledTitleWrapped = styled(StyledTitle)`
    color: #000;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
    margin-bottom: 28px;
    display: flex;
`;

export const StyledFigcaption = styled.figcaption`
    & > span:first-child {
        color: #000;
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        line-height: 151.182%;
    }

    & > span:last-child {
        color: rgba(0, 0, 0, 0.6);
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        line-height: 151.182%;
    }
`;

export const StyledText = styled.p`
    color: #051018;
    font-size: 20px;
    font-weight: 400;
    line-height: 151.182%;
    margin: 0;
    word-wrap: 'wrap';
`;

export const StyledOrangeQuoteSigns = styled.span`
    text-align: center;
    font-family: 'Open Sans';
    font-size: 184px;
    font-style: normal;
    font-weight: 600;
    line-height: 151.182%;
    color: #fff;

    -webkit-text-stroke: 3px #db913a;
    fill: #fff;
`;

export const OrangeQuoteStart = styled(StyledOrangeQuoteSigns)`
    position: absolute;
    left: -80px;
    bottom: 140px;

    @media screen and (max-width: 1387px) {
        left: -80px;
        bottom: 80px;
    }

    @media screen and (max-width: 1000px) {
        display: none;
    }
`;

export const OrangeQuoteEnd = styled(StyledOrangeQuoteSigns)`
    position: absolute;
    right: -40px;
    top: 200px;

    @media screen and (max-width: 1387px) {
        top: 150px;
        right: -20px;
    }

    @media screen and (max-width: 1000px) {
        display: none;
    }
`;
